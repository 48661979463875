<template>
  <v-card :loading="loading">
    <v-sheet class="v-sheet--offset " color="success" elevation="6"> </v-sheet>
    <v-card-title>Identity Verification Check</v-card-title>
    <v-card-subtitle
    v-show="this.time">{{this.time }}  secs</v-card-subtitle>
    
    

    <div class="row xs12 md12">
      <v-row align="center">
        <v-col align="center">
          <!--<v-img
            v-if="!data"
            height="200px"
            width="200px"
            lazy-src="../../../public/img/passport.jpg"
          >
            
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>-->

          <v-img
            :src="image"
            :key="image"
            height="200px"
            width="200px"
            lazy-src="../../../public/img/passport.jpg"
          >
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader v-show="loading" type="image" />
              </v-sheet>
            </template>
          </v-img>
          <v-card-text
            >NAME
            <div v-if="data">
              {{ data.result.firstName }} {{ data.result.surname }}
            </div>

            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader>
          </v-card-text>

          <v-card-text
            >TEMPLATE
            <div v-if="data">{{ data.result.templateName }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader>


        </v-card-text>
          <v-row class="justify-center">

            <span v-if="data">
                  <v-icon
                    size="100"
                    color="green"
                    v-if="data.result.subKey === 'NoFailedChecks'"
                  >
                    mdi-check</v-icon
                  >
                  <v-icon size="100" color="yellow" v-else> mdi-alert</v-icon>
                </span>
                <span v-else-if="loading">
                  <v-progress-circular size="50" indeterminate></v-progress-circular>
                </span>
              
          </v-row>
          <v-list  density="compact" disabled>
            <v-list-item-group color="primary">
              <v-list-item two-line>
                  <v-icon> mdi-passport</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Identity Verification</v-list-item-title>
                  <span v-if="data">
                    <v-list-item-subtitle
                      v-if="data.result.subKey === 'NoFailedChecks'"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{
                      data.result.subKey
                    }}</v-list-item-subtitle>
                  </span>
                </v-list-item-content>
                <span v-if="data">
                  <v-icon
                    color="green"
                    v-if="data.result.subKey === 'NoFailedChecks'"
                  >
                    mdi-check</v-icon
                  >
                  <v-icon color="yellow" v-else> mdi-alert</v-icon>
                </span>
                <span v-else-if="loading">
                  <v-progress-circular indeterminate></v-progress-circular>
                </span>
              </v-list-item>

              <v-list-item two-line v-if="faceMatchVerifyOn">
                <v-list-item-icon>
                  <v-icon> mdi-face-recognition</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Face Match Verification</v-list-item-title>
                  <span v-if="faceMatchData">
                    <v-list-item-subtitle
                      v-if="faceMatchData.subKey === 'MATCH_POSITIVE'"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{
                      faceMatchData.subKey
                    }}</v-list-item-subtitle>
                  </span>
                </v-list-item-content>
                <span v-if="faceMatchData">
                  <v-icon
                    color="green"
                    v-if="faceMatchData.subKey === 'MATCH_POSITIVE'"
                  >
                    mdi-check</v-icon
                  >
                  <v-icon color="yellow" v-else> mdi-alert</v-icon>
                </span>
                <span v-else-if="loading">
                  <v-progress-circular indeterminate></v-progress-circular>
                </span>
              </v-list-item>

              <v-list-item two-line v-if="rfVerifyOn">
                <v-list-item-icon>
                  <v-icon> mdi-credit-card-wireless</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>RF Verification</v-list-item-title>
                  <span v-if="rfVerificationData">
                    <v-list-item-subtitle
                      v-if="
                        rfVerificationData.result.subKey === 'NoFailedChecks'
                      "
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{
                      rfVerificationData.result.subKey
                    }}</v-list-item-subtitle>
                  </span>
                </v-list-item-content>
                <span v-if="rfVerificationData">
                  <v-icon
                    color="green"
                    v-if="rfVerificationData.result.subKey === 'NoFailedChecks'"
                  >
                    mdi-check</v-icon
                  >
                  <v-icon color="yellow" v-else> mdi-alert</v-icon>
                </span>
                <span v-else-if="loading">
                  <v-progress-circular indeterminate></v-progress-circular>
                </span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <!--<v-card-text class="pa-6"
            >POSITIVE VERIFICATIONS / TOTAL VERIFICATIONS
            <div v-if="data">
              {{ data.metadata.numberOfPositiveVerifications }} of
              {{ data.metadata.totalVerifications }}
            </div>
             <v-skeleton-loader class="pa-6"
       v-else
       v-show="loading"
        max-width="200"
        type="list-item"
      ></v-skeleton-loader></v-card-text
          >-->
      </v-row>
    </div>

    <!-- <div class="flex xs12 md12">
            <div class="row row-equal">
              <div align="center"
                class="cards flex xs12 md6 lg3"
                v-for="(data, name) in idvData.actions"
                :key="name">


      <IDVResultItemCard
                    :title="data.verificationType"
                    :score="data.score"
                    :name="data.verificationName"
                    :content="data"
                  />  
    </div>
      </div>
    </div>
-->

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
//  import testData from "../../../public/testData.json";


//import IDVResultItemCard from "@/components/cards/IDVResultItemCard.vue";
export default {
  name: "IDVCheckCard",
  props: {
    title: String,
    data: Object,
    loading: Boolean,
    faceMatchLoading: Boolean,
    rfLoading: Boolean,
    reset: Boolean,
    faceMatchData: Object,
    rfVerificationData: Object,
  },
  data: () => ({
    started: null,
    running: false,
    content: "",
    image: "",
    rawDataDialog: false,
    rawRFDataDialog: false,
    rawFaceMatchDataDialog: false,
    documentVerifyOn: false,
    rfVerifyOn: false,
    faceMatchVerifyOn: false,
    time: null,
    start:0,
    end:0,
  }),
  mounted() {
    if (localStorage.documentVerifySwitch) {
      this.documentVerifyOn = JSON.parse(localStorage.documentVerifySwitch);
    }
    if (localStorage.rfVerifySwitch) {
      this.rfVerifyOn = JSON.parse(localStorage.rfVerifySwitch);
    }
    if (localStorage.faceMatchVerifySwitch) {
      this.faceMatchVerifyOn = JSON.parse(localStorage.faceMatchVerifySwitch);
    }
  },

  created() {},
  watch: {
    data: function() {
      if (this.data) {
        this.image = "data:image/jpg;base64," + this.data.result.portrait;
      } else {
        this.image = "../../../public/img/passport.jpg";
      }

      if (localStorage.documentVerifySwitch) {
        this.documentVerifyOn = JSON.parse(localStorage.documentVerifySwitch);
      }
      if (localStorage.rfVerifySwitch) {
        this.rfVerifyOn = JSON.parse(localStorage.rfVerifySwitch);
      }
      if (localStorage.faceMatchVerifySwitch) {
        this.faceMatchVerifyOn = JSON.parse(localStorage.faceMatchVerifySwitch);
      }
    },
    loading: function() {
      if(this.loading){
        this.start = new Date().getTime();
        this.time = null;
      }else{
        this.end = new Date().getTime();
        this.time = Math.trunc((this.end - this.start)/1000);
      }
      if (localStorage.documentVerifySwitch) {
        this.documentVerifyOn = JSON.parse(localStorage.documentVerifySwitch);
      }
      if (localStorage.rfVerifySwitch) {
        this.rfVerifyOn = JSON.parse(localStorage.rfVerifySwitch);
      }
      if (localStorage.faceMatchVerifySwitch) {
        this.faceMatchVerifyOn = JSON.parse(localStorage.faceMatchVerifySwitch);
      }

    },
    faceMatchData: function() {},
    reset: function() {
      this.resetData();
      this.time = null;
    }
  },
  computed: {
    // a computed getter
    idvResults: function() {
      return this.data;
      // console.log(data_buffer.toString('hex'));
      // console.log(data_buffer.toString('utf8'))
    },
    idvFaceMatchResults: function() {
      return this.faceMatchData;
    },

    bytedata: function() {
      var data_buffer = Buffer.from(this.content, "base64");
      return data_buffer.toString("hex");
    }
  },
  methods: {
    resolveimage: function() {
      if (this.data) {
        return "data:image/jpg;base64," + this.data.result.portrait;
      } else {
        return "../../../public/img/passport.jpg";
      }
    },
    resetData: function() {
      this.data = {};
    }
  }
};
</script>

<style scoped>
.cards {
  padding: 10px;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.v-sheet--offset {
  top: 12px;
  position: relative;
}

.timer {
  display: inline;
}
</style>
