<template>
  <v-app id="app-layout">
    <span v-bind:class="[useBackground ? 'bg' : '']">
      <!--<v-navigation-drawer v-model="drawerRight" app clipped right>
      <v-list dense>
        <v-list-item @click.stop="deviceDetails = !deviceDetails">
          <v-list-item-action>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Device Details</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="readerEvents = !readerEvents">
          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reader Event Log</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="disconnect">
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Disconnect</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>-->

      <v-app-bar app clipped-right color="primary" dark hide-on-scroll>
        <v-app-bar-nav-icon @click.stop="drawerLeft = !drawerLeft" />
        <v-toolbar-title>
          {{ this.deviceInfo.model }} - {{ this.deviceInfo.serial }}
        </v-toolbar-title>

        <template v-slot:extension>
          <v-tabs color="secondary" grow center-active v-model="tab">
            <v-tab v-for="tabItem in tabItems" :key="tabItem.tab">
              {{ tabItem.tab }}
            </v-tab>
          </v-tabs>
        </template>
        <v-spacer />

        <!--<v-btn icon @click.stop="drawerRight = !drawerRight">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>-->
      </v-app-bar>

      <v-navigation-drawer v-model="drawerLeft" app>
        <v-list dense>
          <!-- <v-list-item @click.stop="showReaderSettings">
            <v-list-item-action>
              <v-icon>mdi-smart-card-reader-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Reader Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
          <v-list-item @click.stop="idvSettingsMenu = !idvSettingsMenu">
            <v-list-item-action>
              <v-icon>mdi-smart-card-reader-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>IDV Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="applicationSettings = !applicationSettings">
            <v-list-item-action>
              <v-icon>mdi-apps</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Application Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop="deviceDetails = !deviceDetails">
            <v-list-item-action>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Device Details</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="readerEvents = !readerEvents">
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Reader Event Log</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="disconnect">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Disconnect</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer v-model="idvSettingsMenu" fixed temporary>
        <v-subheader>IDV Settings</v-subheader>

        
        <v-list-item link @click.stop="showIDVSettings = !showIDVSettings">
          <v-list-item-icon>
            <v-icon>mdi-server-network</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Server Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-switch
            v-model="documentVerifySwitch"
            :disabled="disableSwitch"
            :loading="loadingSwitch"
            label="Document Verify"
          ></v-switch>
        </v-list-item>

        <v-list-item>
          <v-switch
            v-show="false"
            v-model="rfVerifySwitch"
            :disabled="disableSwitch"
            :loading="loadingSwitch"
            label="RF Verify"
          ></v-switch>
        </v-list-item>

        <v-list-item>
          <v-switch
            v-show="false"
            v-model="faceMatchVerifySwitch"
            :disabled="disableSwitch"
            :loading="loadingSwitch"
            label="Face Match Verify"
          ></v-switch>
        </v-list-item>

      </v-navigation-drawer>

      <v-navigation-drawer v-model="applicationSettings" fixed temporary>
        <v-subheader>Application Settings</v-subheader>
        <v-list-item>
          <v-switch
            v-model="darkMode"
            class="ma-2"
            label="Dark Mode"
          ></v-switch>
        </v-list-item>

        <v-list-item>
          <v-switch
            v-model="useBackground"
            class="ma-2"
            label="Use Background"
          ></v-switch>
        </v-list-item>

        <v-list-item>
          <v-select
            v-model="primarySelect"
            @input="primarySelection"
            label="Primary Color"
            :items="colorOptions"
            outlined
            dense
            hint="Default - Blue"
            persistent-hint
          ></v-select>
        </v-list-item>

        <v-list-item>
          <v-switch
            v-if="features.facematch"
            v-model="faceMatch"
            class="ma-2"
            label="Do 1:1 Face Match"
          ></v-switch>
        </v-list-item>
      </v-navigation-drawer>

      <v-tabs-items class="transparent" v-model="tab">
        <v-tab-item key="IDVCheck">
          <div class="flex xs12 md12">
            <div class="row row-equal">
              <div
                class="cards flex xs12 md12 lg12"
                v-for="idvcheckcard in idvcheckcards"
                :key="idvcheckcard.title"
              >
                <IDVCheckCard
                  v-bind:title="idvcheckcard.title"
                  :loading="idvcheckcard.loading"
                  :data="idvcheckcard.data"
                  :faceMatchLoading="idvcheckcard.faceMatchLoading"
                  :rfLoading="idvcheckcard.rfLoading"
                  :faceMatchData="idvcheckcard.faceMatchData"
                  :rfVerificationData="idvcheckcard.rfVerificationData"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="IDVResults">
          <div class="flex xs12 md12">
            <div class="row row-equal">
              <div
                class="cards flex xs12 md12 lg12"
                v-for="idvresultcard in idvresultcards"
                :key="idvresultcard.title"
              >
                <IDVResultCard
                  v-bind:title="idvresultcard.title"
                  :loading="idvresultcard.loading"
                  :data="idvresultcard.data"
                  :faceMatchLoading="idvresultcard.faceMatchLoading"
                  :rfLoading="idvresultcard.rfLoading"
                  :faceMatchData="idvresultcard.faceMatchData"
                  :rfVerificationData="idvresultcard.rfVerificationData"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="Images">
          <div class="flex xs12 md12">
            <div class="row row-equal">
              <div
                class="cards flex xs12 md6 lg3"
                v-for="card in cards"
                :key="card.title"
              >
                <component
                  v-bind:is="card.componentType"
                  v-bind:title="card.title"
                  v-bind:content="card.content"
                  v-bind:loading="card.loading"
                />
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="eData">
          <div class="flex xs12 md12">
            <div class="row row-equal">
              <div
                class="cards flex xs12 md6 lg3"
                v-for="edatacard in edatacards"
                :key="edatacard.title"
              >
                <component
                  v-bind:is="edatacard.componentType"
                  v-bind:title="edatacard.title"
                  v-bind:content="edatacard.content"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="FaceMatch">
          <div class="flex xs12 md12">
            <div class="row row-equal">
              <div
                class="cards flex xs12 md6 lg3"
                v-for="facematchcard in facematchcards"
                :key="facematchcard.title"
              >
                <data-card
                  v-bind:datatype="facematchcard.title"
                  v-bind:data="facematchcard.data"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <v-navigation-drawer v-model="deviceDetails" fixed temporary>
        <v-list-item> <b> Model: </b> {{ this.deviceInfo.model }} </v-list-item>
        <v-list-item>
          <b> Serial: </b> {{ this.deviceInfo.serial }}
        </v-list-item>
        <v-list-item>
          <b> Version: </b> {{ this.deviceInfo.version }}
        </v-list-item>
        <v-list-item>
          <b> RF: </b> {{ this.deviceInfo.hasRf ? "Yes" : "No" }}
        </v-list-item>
        <v-list-item>
          <b> Two-sided: </b>
          {{ this.deviceInfo.isTwoSided ? "Yes" : "No" }}
        </v-list-item>
        <!-- <v-list-item>
        <b> Firmware Update: </b>
        {{
          this.deviceInfo.updateAvailable ? "Available" : "Not Available"
        }}</v-list-item
      > -->
        <v-list-item>
          <b> Reboot Needed: </b>
          {{ this.deviceInfo.rebootPending ? "Yes" : "No" }}</v-list-item
        >
      </v-navigation-drawer>

      <v-navigation-drawer v-model="readerEvents" fixed temporary>
        <v-list-item-content
          v-for="readerevent in readerevents"
          v-bind:key="readerevent.id"
        >
          <v-list-item-title>{{ readerevent.anevent }}</v-list-item-title>
          <v-list-item-subtitle>{{ readerevent.time }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-navigation-drawer>

      <FooterBar />
      <ReaderSettings
        v-bind:readerSettings="readerSettings"
        v-bind:showDialog="readerSettingsVisibility"
        @close="closeReaderSettings"
      />

      <v-main>
        <router-view
          @readerevent="updateevent"
          @newcapture="cleardashboard"
          @imagecaptured="addimagecard"
          @imagereceived="addimage"
          @edatacaptured="addedatacard"
          @edatareceived="addedata"
          @datacaptured="addDataCard"
          @datareceived="addData"
          @aamvadatacaptured="addAamvaDataCard"
          @aamvadatareceived="addAamvaData"
          @barcodedatacaptured="addBarcodeDataCard"
          @barcodedatareceived="addBarcodeData"
          @facematchresult="addfacematchResult"
          @capturecomplete="complete"
          @capturedocfound="captureDocFound"
          @idvresultcaptured="addIDVResult"
          @idvresultreceived="addIDVResultData"
          @idvfacematchresultreceived="addIDVFaceMatchResultData"
          @idvrfresultreceived="addIDVRFResultData"
          v-bind:doFaceMatch="faceMatch"
        />
      </v-main>
    </span>
    <v-dialog v-model="showIDVSettings" persistent max-width="500">
      <v-card>
        <v-list rounded three-line>
          <v-subheader
            >Welcome to the IDV Demo! Lets get set up before proceeding.
          </v-subheader>
          <v-form ref="idvform" v-model="idvSettingsValid" lazy-validation>
            <v-text-field
            :disabled="true"
              v-model="idvSettings.api_key"
              label="API Key"
              :rules="[v => !!v || 'API Key is required']"
              required
            ></v-text-field>

            <v-text-field
              :disabled=true
              v-model="idvSettings.idv_server"
              label="IDV Server"
              :rules="[v => !!v || 'IDV Server is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.verification_uri"
              label="Verification Uri"
              :rules="[v => !!v || 'Verification Uri is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.face_match_uri"
              label="Face Match Uri"
              :rules="[v => !!v || 'Face Match Uri is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.app_client_id"
              label="App Client ID"
              :rules="[v => !!v || 'App Client ID is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.app_client_secret"
              label="App Client Secret"
              :rules="[v => !!v || 'App Client Secret is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.authentication_server"
              label="Authentication Server"
              :rules="[v => !!v || 'Authentication Server is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.authentication_uri"
              label="Authentication Uri"
              :rules="[v => !!v || 'Authentication Uri is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.primary_channel"
              label="Primary Channel"
              :rules="[v => !!v || 'Primary Channel is required']"
              required
            ></v-text-field>
            <v-text-field
              :disabled=true
              v-model="idvSettings.secondary_channel"
              label="Secondary Channel"
              :rules="[v => !!v || 'Secondary Channel is required']"
              required
            ></v-text-field>

            <v-checkbox
              v-model="idvSettingsCheckbox"
              label="Save these settings?"
            ></v-checkbox>
          </v-form>

          <v-card-actions>
            
            <v-file-input label="Upload Settings" accept=".json"   v-model="idvFile"> </v-file-input>
           
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="closeIDVSettings"
            >
              Close
            </v-btn>

            <v-btn
              color="primary"
              @click="saveIDVSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="idvAlertDialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>IDV Error</v-toolbar>
          <v-card-text v-model="idvErrorMessage">
            <div class="text-h5 pa-12">{{ idvErrorMessage }}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-app>
</template>

<script>
import FooterBar from "./menu/FooterBar";

import ReaderSettings from "./menu/ReaderSettingsMenu";
import ImageCard from "@/components/cards/ImageCard.vue";
import DataCard from "@/components/cards/DataCard.vue";
import BarcodeDataCard from "@/components/cards/BarcodeDataCard.vue";
import CodelineCard from "@/components/cards/CodelineCard.vue";
import IDVResultCard from "@/components/cards/IDVResultCard.vue";
import AamvaDataCard from "@/components/cards/AamvaDataCard.vue";
import EdataCodelineCard from "@/components/cards/EdataCodelineCard.vue";
import EdataValidationCard from "@/components/cards/EdataValidationCard.vue";
import IDVCheckCard from "@/components/cards/IDVCheckCard.vue";
import colors from "vuetify/lib/util/colors";

import { mapGetters, mapActions /*, mapState */ } from "vuex";

export default {
  props: {
    source: String,
    webApiSession: Object,
    deviceInfo: Object
  },

  components: {
    FooterBar,
    ImageCard,
    DataCard,
    BarcodeDataCard,
    CodelineCard,
    AamvaDataCard,
    EdataCodelineCard,
    EdataValidationCard,
    ReaderSettings,
    IDVResultCard,
    IDVCheckCard
  },

  data() {
    return {
      // drawer state
      drawerRight: null,
      drawerLeft: null,
      readerSettingsVisibility: false,
      idvAlertDialog: false,
      idvErrorMessage: "",
      right: false,
      deviceDetails: false,
      readerEvents: false,
      applicationSettings: false,
      idvSettingsMenu: false,
      documentVerifySwitch: true,
      rfVerifySwitch: false,
      faceMatchVerifySwitch: false,
      disableSwitch: true,
      loadingSwitch: false,
      left: false,
      tab: null,
      tabItems: [],
      cards: [],
      edatacards: [],
      facematchcards: [],
      idvresultcards: [],
      idvcheckcards: [],
      exists: null,
      tabExists: null,
      readerevents: [],
      faceMatch: false,
      twoSidedDocuments: false,
      acuant: false,
      darkMode: false,
      useBackground: false,
      primarySelect: "",
      colorOptions: Object.keys(colors),
      showIDVSettings: false,
      idvFile:null,
      isAPreviousConnection: false,
      idvSettingsValid: false,
      idvSettings: {
        remote_image_compression_level: 70,
        remote_photo_compression_level: 100,
        remote_image_scale_down: 2,
        api_key: "",
        idv_server: "",
        verification_uri: "",
        face_match_uri: "",
        app_client_id: "",
        app_client_secret: "",
        authentication_server: "",
        authentication_uri: "",
        default_request_timeout: 60,
        primary_channel: "defaultL2",
        secondary_channel: "default",
        debug_file_saves: 3
      },
      idvSettingsCheckbox: true,
      idvResultCount: 1
    };
  },
  methods: {
    ...mapActions("webapi", [
      "getReaderSettings",
      "closeConnection",
      "saveSettings"
    ]),
    async showReaderSettings() {
      await this.getReaderSettings();
      this.readerSettingsVisibility = true;
    },
    closeReaderSettings() {
      this.readerSettingsVisibility = false;
    },
    getDateTime() {
      return new Date().toLocaleString();
    },
    complete() {},
    setsettingscomplete() {
      this.loadingSwitch = false;
      this.disableSwitch = false;
    },
    addimage(imagetype, imagedata) {
      let index = this.cards.findIndex(item => {
        return imagetype === item.title;
      });
      this.cards[index].content = imagedata;
      this.cards[index].loading = false;
    },
    addimagecard(imagetype) {
      this.checkIfTabExists("Images");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "Images" });
      }

      if (!this.checkIfExist(imagetype)) {
        this.cards.push({
          title: imagetype,
          componentType: "image-card",
          src: "",
          flex: 12,
          loading: true,
          useBackground: true
        });
      }
    },
    addedata(datatype, data) {
      let index = this.edatacards.findIndex(item => {
        return datatype === item.title;
      });

      if (datatype.includes("DG2 Photo")) {
        this.edatacards[index].content = data;
        this.edatacards[index].loading = false;
      } else if (datatype.includes("DG1 Codeline")) {
        this.edatacards[index].content = data;
        this.edatacards[index].loading = false;
      } else if (datatype.includes("Validation")) {
        this.edatacards[index].content = data;
      }
    },
    addedatacard(datatype) {
      this.checkIfTabExists("eData");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "eData" });
      }

      //Placeholder for Demoing Face Match Results
      // this.checkIfTabExists("Face Match");
      // if (!this.tabExists) {
      //   this.tabItems.push({ tab: "Face Match" });
      //   this.facematchcards.push({
      //     title: "Face Match",
      //     data: "Face Match Results will Show here",
      //     loading: true
      //   });
      // }
      this.checkIfTabExists("IDV Results");
      if (!this.tabExists) {
        this.tabItems.push({ tab: "IDV Results" });
      }

      this.checkIfTabExists("IDV Check");
      if (!this.tabExists) {
        this.tabItems.push({ tab: "IDV Check" });
      }

      if (!this.checkIfEdataExist(datatype)) {
        if (datatype.includes("DG2 Photo")) {
          this.edatacards.push({
            componentType: "image-card",
            title: datatype,
            content: "",
            flex: 12
          });
        } else if (datatype.includes("DG1 Codeline")) {
          this.edatacards.push({
            componentType: "EdataCodelineCard",
            title: datatype,
            content: {},
            flex: 12
          });
        } else if (datatype.includes("Validation")) {
          this.edatacards.push({
            componentType: "EdataValidationCard",
            title: datatype,
            content: {}
          });
        }
      }
    },
    addData(datatype, data) {
      console.log(`addData - ${datatype} ----------------------------`);
      console.log("" + datatype + " " + JSON.stringify(data));
      let index = this.cards.findIndex(item => {
        return datatype === item.title;
      });

      const obj = this.cards[index];
      obj.componentType = "CodelineCard";
      obj.content = data.codeline_data;
      obj.loading = false;
    },
    addDataCard(datatype) {
      console.log(`addDataCard - ${datatype} ----------------------------`);
      this.checkIfTabExists("Images");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "Images" });
      }

      if (!this.checkIfExist(datatype)) {
        this.cards.push({
          title: datatype,
          componentType: "image-card",
          flex: 12,
          loading: true
        });
      }
    },
    addAamvaData(datatype, data) {
      console.log(`addData - ${datatype} ----------------------------`);
      console.log("" + datatype + " " + JSON.stringify(data));
      let index = this.cards.findIndex(item => {
        return datatype === item.title;
      });

      const obj = this.cards[index];
      obj.componentType = "AamvaDataCard";
      obj.content = data;
      obj.loading = false;
    },
    addAamvaDataCard(datatype) {
      console.log(`addDataCard - ${datatype} ----------------------------`);
      this.checkIfTabExists("Images");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "Images" });
      }

      if (!this.checkIfExist(datatype)) {
        this.cards.push({
          title: datatype,
          componentType: "image-card",
          flex: 12,
          loading: true
        });
      }
    },
    addBarcodeData(datatype, data) {
      console.log(`addData - ${datatype} ----------------------------`);
      console.log("" + datatype + " " + JSON.stringify(data));
      let index = this.cards.findIndex(item => {
        return datatype === item.title;
      });

      const obj = this.cards[index];
      obj.componentType = "barcode-data-card";
      obj.content = data;
      obj.loading = false;
    },

    addBarcodeDataCard(datatype) {
      console.log(`addDataCard - ${datatype} ----------------------------`);
      this.checkIfTabExists("Images");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "Images" });
      }

      if (!this.checkIfExist(datatype)) {
        this.cards.push({
          title: datatype,
          componentType: "image-card",
          flex: 12,
          loading: true
        });
      }
    },
    addfacematchResult(datatype, data) {
      this.checkIfTabExists("Face Match");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "Face Match" });
      }

      this.facematchcards.push({ title: datatype, data });
    },
    addIDVResult(datatype) {
      this.checkIfTabExists("IDV Results");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "IDV Results" });
      }

      if (!this.checkIfIDVExist(datatype)) {
        this.idvresultcards.push({
          title: datatype,
          loading: false,
          faceMatchLoading: false,
          rfLoading: false,
          reset: true
        });
      }
    },
    addIDVCheck(datatype) {
      this.checkIfTabExists("IDV Check");

      if (!this.tabExists) {
        this.tabItems.push({ tab: "IDV Check" });
      }

      if (!this.checkIfIDVCheckExist(datatype)) {
        this.idvcheckcards.push({
          title: datatype,
          loading: false,
          faceMatchLoading: false,
          rfLoading: false,
          reset: true
        });
      }
    },
    addIDVRFResultData(datatype, data) {
      let result = JSON.parse(data);
      console.log("-------RF Result ---------");
      console.log(result);

      let index = this.idvresultcards.findIndex(item => {
        return "IDV Results" === item.title;
      });
      let obj = this.idvresultcards[index];

      obj.rfVerificationData = result;
      obj.rfLoading = false;

      index = this.idvcheckcards.findIndex(item => {
        return "IDV Check" === item.title;
      });
      obj = this.idvcheckcards[index];

      obj.rfVerificationData = result;
      obj.rfLoading = false;
    },
    addIDVFaceMatchResultData(datatype, data) {
      let result = JSON.parse(data);

      let index = this.idvresultcards.findIndex(item => {
        return "IDV Results" === item.title;
      });
      let obj = this.idvresultcards[index];

      obj.faceMatchData = result;
      obj.faceMatchLoading = false;

      index = this.idvcheckcards.findIndex(item => {
        return "IDV Check" === item.title;
      });
      obj = this.idvcheckcards[index];

      obj.faceMatchData = result;
      obj.faceMatchLoading = false;
    },
    addIDVResultData(datatype, data) {
      // this.idvresultcards.push({
      //     title: datatype,
      //     loading: true,
      //     data: JSON.parse(  data.slice(0, -1) )
      //   });
      let index = this.idvresultcards.findIndex(item => {
        return datatype === item.title;
      });
      let obj = this.idvresultcards[index];

      try {
        let idvResponse = JSON.parse(data);
        if (idvResponse.Message) {
          obj.loading = false;
          this.idvAlertDialog = true;
          this.idvErrorMessage = idvResponse.Message;
        } else {
          obj.data = JSON.parse(data);
          obj.loading = false;
        }
      } catch (e) {
        if (data === "No response from Server") {
          obj.loading = false;
          this.idvAlertDialog = true;
          this.idvErrorMessage = "Error with connecting to the server";
        } else {
          obj.loading = false;
          this.idvAlertDialog = true;
          this.idvErrorMessage = data;
        }
      }

      index = this.idvcheckcards.findIndex(item => {
        return "IDV Check" === item.title;
      });
      obj = this.idvcheckcards[index];

      try {
        let idvResponse = JSON.parse(data);
        if (idvResponse.Message) {
          obj.loading = false;
          this.idvAlertDialog = true;
          this.idvErrorMessage = idvResponse.Message;
        } else {
          obj.data = JSON.parse(data);
          obj.loading = false;
        }
      } catch (e) {
        if (data === "No response from Server") {
          obj.loading = false;
          this.idvAlertDialog = true;
          this.idvErrorMessage = "Error with connecting to the server";
        } else {
          obj.loading = false;
          this.idvAlertDialog = true;
          this.idvErrorMessage = data;
        }
      }
    },
    checkIfEdataExist(datatype) {
      this.exists = this.edatacards.some(type => {
        return type.title === datatype;
      });
      return this.exists;
    },
    checkIfIDVExist(datatype) {
      this.exists = this.idvresultcards.some(type => {
        return type.title === datatype;
      });
      return this.exists;
    },
    checkIfIDVCheckExist(datatype) {
      this.exists = this.idvcheckcards.some(type => {
        return type.title === datatype;
      });
      return this.exists;
    },
    checkIfExist(imagetype) {
      this.exists = this.cards.some(type => {
        return type.title === imagetype;
      });
      return this.exists;
    },
    checkIfTabExists(aTab) {
      return (this.tabExists = this.tabItems.some(type => {
        return type.tab === aTab;
      }));
    },
    cleardashboard() {
      this.cards = [];
      this.edatacards = [];
      this.idvresultcards = [];
      this.idvcheckcards = [];
      this.addIDVCheck("IDV Check");
      this.addIDVResult("IDV Results");

      let index = this.idvresultcards.findIndex(item => {
        return "IDV Results" === item.title;
      });
      let obj = this.idvresultcards[index];
      obj.loading = false;
    },
    captureDocFound() {
      let index = this.idvresultcards.findIndex(item => {
        return "IDV Results" === item.title;
      });
      let obj = this.idvresultcards[index];
      obj.loading = true;
      obj.faceMatchLoading = true;
      obj.rfLoading = true;

      index = this.idvcheckcards.findIndex(item => {
        return "IDV Check" === item.title;
      });
      obj = this.idvcheckcards[index];
      obj.loading = true;
      obj.faceMatchLoading = true;
      obj.rfLoading = true;
    },
    updateevent(event) {
      this.readerevents.push({ anevent: event, time: this.getDateTime() });
    },
    disconnect() {
      this.closeConnection();
      this.$router.push({ name: "connect" });
    },
    primarySelection() {
      this.$vuetify.theme.themes.light.primary = colors[this.primarySelect];
      this.$vuetify.theme.themes.dark.primary = colors[this.primarySelect];
    },
    setIdvSettingsFromFile(event){
      let str = event.target.result;
      let json = JSON.parse(str)

      console.log(json.APIKey)

      this.idvSettings.api_key = json.APIKey;
      this.idvSettings.idv_server = json.IDVServer;
      this.idvSettings.verification_uri = json.VerificationUri;
      this.idvSettings.face_match_uri = json.FaceMatchUri;
      this.idvSettings.app_client_id = json.AppClientId;
      this.idvSettings.app_client_secret = json.AppClientSecret;
      this.idvSettings.authentication_server = json.AuthenticationServer;
      this.idvSettings.authentication_uri = json.AuthenticationUri;
      this.idvSettings.primary_channel = json.PrimaryChannel;
      this.idvSettings.secondary_channel = json.SecondaryChannel;

      
    },
    closeIDVSettings(){
      this.showIDVSettings = false;
    },
    
    saveIDVSettings() {
      if(this.idvFile){
        console.log(this.idvFile)

        let reader = new FileReader();
        reader.onload = this.setIdvSettingsFromFile;

        reader.readAsText(this.idvFile)

      }

    

      this.validateIDVForm();

      setTimeout(() => {
        if (this.idvSettingsValid) {
          if (this.idvSettingsCheckbox) {
            localStorage.setItem(
              "idvSettings",
              JSON.stringify(this.idvSettings)
            );
          }
          this.showIDVSettings = false;
        } else {
          console.log("not valid");
        }
      }, 1000);
      this.getReaderSettings();
    },
    validateIDVForm() {
      this.$refs.idvform.validate();
    },
    async setSettingForIDV() {
      if (this.readerSettings) {
        console.log("_____---___---___---___");
        console.log(this.readerSettings);
        this.readerSettings.settings.document_processing = this.idvSettings;

        if (this.documentVerifySwitch) {
          //Settings for Document Verify Only
          this.readerSettings.settings.data_to_send.codeline_data = true;
          this.readerSettings.settings.data_to_send.ir_image = 1;
          this.readerSettings.settings.data_to_send.smart_card = true;
          this.readerSettings.settings.data_to_send.uv_image = 1;
          this.readerSettings.settings.data_to_send.visible_image = 2;

          this.readerSettings.settings.data_to_send.idv_remote_document_verify = true;

          this.readerSettings.settings.rf_data_to_send.dg2_face_jpeg = 6;
          this.readerSettings.settings.rf_data_to_send.ef_com_file = 6;
          this.readerSettings.settings.rf_data_to_send.ef_sod_file = 6;
          this.readerSettings.settings.rf_data_to_send.dg2_file = 7;
          this.readerSettings.settings.rf_data_to_send.dg1_file = 2;
          this.readerSettings.settings.rf_data_to_send.dg1_mrz_data = 1;

          this.readerSettings.settings.logging.log_level = 5;
        } else {
          this.readerSettings.settings.data_to_send.idv_remote_document_verify = false;
        }

        if (this.faceMatchVerifySwitch) {
          //Settings for Face Match Verify
          this.readerSettings.settings.data_to_send.codeline_data = true;
          this.readerSettings.settings.data_to_send.ir_image = 1;
          this.readerSettings.settings.data_to_send.smart_card = true;
          this.readerSettings.settings.data_to_send.photo_image = 2;
          this.readerSettings.settings.data_to_send.uv_image = 1;
          this.readerSettings.settings.data_to_send.visible_image = 2;
          this.readerSettings.settings.rf_data_to_send.dg2_face_jpeg = 6;
          this.readerSettings.settings.data_to_send.idv_remote_facematch_verify = true;
        } else {
          this.readerSettings.settings.data_to_send.idv_remote_facematch_verify = false;
        }

        if (this.rfVerifySwitch) {
          //Settings for RF Verify
          this.readerSettings.settings.data_to_send.codeline_data = true;
          this.readerSettings.settings.data_to_send.smart_card = true;
          this.readerSettings.settings.rf_data_to_send.dg2_face_jpeg = 6;
          this.readerSettings.settings.rf_data_to_send.ef_com_file = 6;
          this.readerSettings.settings.rf_data_to_send.ef_sod_file = 6;
          this.readerSettings.settings.rf_data_to_send.dg2_file = 7;
          this.readerSettings.settings.rf_data_to_send.dg1_file = 2;
          this.readerSettings.settings.rf_data_to_send.dg1_mrz_data = 1;
          this.readerSettings.settings.data_to_send.idv_remote_rf_verify = true;
        } else {
          this.readerSettings.settings.data_to_send.idv_remote_rf_verify = false;
        }

        await this.saveSettings(this.readerSettings);
      }
    }
  },
  mounted() {
    if (localStorage.faceMatchSwitch) {
      this.faceMatch = JSON.parse(localStorage.faceMatchSwitch);
    }
    if (localStorage.twoSidedDocumentSwitch) {
      this.twoSidedDocuments = JSON.parse(localStorage.twoSidedDocumentSwitch);
    }
    if (localStorage.darkModeSwitch) {
      this.darkMode = JSON.parse(localStorage.darkModeSwitch);
    }
    if (localStorage.backgroundSwitch) {
      this.useBackground = JSON.parse(localStorage.backgroundSwitch);
    }
    if (localStorage.documentVerifySwitch) {
      this.documentVerifySwitch = JSON.parse(localStorage.documentVerifySwitch);
    }
    if (localStorage.rfVerifySwitch) {
      this.rfVerifySwitch = JSON.parse(localStorage.rfVerifySwitch);
    }
    if (localStorage.faceMatchVerifySwitch) {
      this.faceMatchVerifySwitch = JSON.parse(
        localStorage.faceMatchVerifySwitch
      );
    }

    if (!localStorage.getItem("idvSettings")) {
      this.showIDVSettings = true;
    } else {
      this.idvSettings = JSON.parse(localStorage.getItem("idvSettings"));
      this.getReaderSettings();
    }
    this.webApiSession.on("SETTINGS_UPDATED", data => {
      if (data.status === "ok") {
        this.setsettingscomplete();
      }
    });
    this.addIDVCheck("IDV Check");
    this.addIDVResult("IDV Results");
  },
  watch: {
    darkMode(isOn) {
      this.$vuetify.theme.dark = isOn;
      localStorage.darkModeSwitch = isOn;
    },
    useBackground(isOn) {
      localStorage.backgroundSwitch = isOn;
    },
    isConnected(oldval, newVal) {
      if (newVal === false) {
        this.$router.push({ name: "connect" });
      }
    },
    readerSettings() {
      this.setSettingForIDV();
    },
    documentVerifySwitch: function() {
      this.disableSwitch = true;
      this.loadingSwitch = true;
      localStorage.documentVerifySwitch = this.documentVerifySwitch;
      this.setSettingForIDV();
    },
    rfVerifySwitch: function() {
      this.disableSwitch = true;
      this.loadingSwitch = true;
      localStorage.rfVerifySwitch = this.rfVerifySwitch;
      this.setSettingForIDV();
    },
    faceMatchVerifySwitch: function() {
      this.disableSwitch = true;
      this.loadingSwitch = true;
      this.setSettingForIDV();
      localStorage.faceMatchVerifySwitch = this.faceMatchVerifySwitch;
    }
  },
  computed: {
    ...mapGetters(["features"]),
    ...mapGetters("webapi", ["readerSettings", "isConnected"])
    // ...mapState({
    //   readerSettingsVisibility: state => state.nav.readerSettingsVisibility,
    //   drawerRightState: state => state.nav.drawerRight,
    //   drawerLeft: state => state.nav.drawerLeft
    // }),
  }
};
</script>
<style scoped>
.cards {
  padding: 20px;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.transparent {
  background: transparent;
}
.bg {
  width: 100%;
  height: 100%;
  background: url("../../public/img/background_2.png") no-repeat center center;
  background-size: cover;
}
</style>
