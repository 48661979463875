<template>
  <v-card :loading="loading">
    <v-sheet class="v-sheet--offset " color="success" elevation="6"> </v-sheet>
    <v-card-title>Identification</v-card-title>

    <div class="row xs12 md12">
      <v-row align="center">
        <v-col align="center" class="flex xs12 md6 pa-0 pt-6">
          <!--<v-img
            v-if="!data"
            height="200px"
            width="200px"
            lazy-src="../../../public/img/passport.jpg"
          >
            
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>-->

          <v-img
            :src="image"
            :key="image"
            height="200px"
            width="200px"
            lazy-src="../../../public/img/passport.jpg"
          >
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader v-show="loading" type="image" />
              </v-sheet>
            </template>
          </v-img>
          <v-card-text
            >NAME
            <div v-if="data">
              {{ data.result.firstName }} {{ data.result.surname }}
            </div>

            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader>
          </v-card-text>
          <v-card-text
            >GENDER
            <div v-if="data">{{ data.result.gender }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
          <v-card-text
            >COUNTRY
            <div v-if="data">{{ data.result.country }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
        </v-col>
        <v-col align="center" class="flex xs12 md6 pa-0 ">
          <v-card-text
            >BIRTHDATE
            <div v-if="data">{{ data.result.birthDate }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
          <v-card-text
            >ISSUING DATE
            <div v-if="data">{{ data.result.issuingDate }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
          <v-card-text
            >EXPIRY DATE
            <div v-if="data">{{ data.result.expiryDate }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
          <v-card-text
            >DOCUMENT TYPE
            <div v-if="data">{{ data.result.documentType }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
          <v-card-text
            >TEMPLATE
            <div v-if="data">{{ data.result.templateName }}</div>
            <v-skeleton-loader
              v-else
              v-show="loading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>

          <!--<v-card-text class="pa-6"
            >POSITIVE VERIFICATIONS / TOTAL VERIFICATIONS
            <div v-if="data">
              {{ data.metadata.numberOfPositiveVerifications }} of
              {{ data.metadata.totalVerifications }}
            </div>
             <v-skeleton-loader class="pa-6"
       v-else
       v-show="loading"
        max-width="200"
        type="list-item"
      ></v-skeleton-loader></v-card-text
          >-->
        </v-col>
      </v-row>
    </div>

    <v-divider v-if="faceMatchVerifyOn" class="mx-4 ma-6"></v-divider>

    <v-card-title v-if="faceMatchVerifyOn">Face Match Results</v-card-title>

    <div v-if="faceMatchVerifyOn" class="row xs12 md12">
      <v-row align="center">
        <v-col align="center" class="flex xs12 md6 pa-0 pt-6">
          <v-card-text
            >RESULT
            <div v-if="faceMatchData">{{ faceMatchData.result }}</div>
            <v-skeleton-loader
              v-else
              v-show="faceMatchLoading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
        </v-col>
        <v-col align="center" class="flex xs12 md6 pa-0 pt-6">
          <v-card-text
            >SUBKEY
            <div v-if="faceMatchData">{{ faceMatchData.subKey }}</div>
            <v-skeleton-loader
              v-else
              v-show="faceMatchLoading"
              max-width="200"
              type="list-item"
            ></v-skeleton-loader
          ></v-card-text>
        </v-col>
      </v-row>
    </div>

    <v-divider v-if="rfVerifyOn" class="mx-4 ma-6"></v-divider>
    <v-expansion-panels v-if="rfVerifyOn" accordion focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-card-title v-if="rfVerificationData"
            >RF Verification Results -
            {{ rfVerificationData.result.subKey }}
          </v-card-title>
          <v-card-title v-else>RF Verification Results</v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="flex xs12 md12 pa-6">
            <div v-if="rfVerificationData">
              <v-row justify="center">
                <v-expansion-panels accordion focusable>
                  <v-expansion-panel
                    v-for="(data, name) in rfVerificationData.result.chipResult
                      .actions"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      <v-row class="xs12 md12">
                        <v-col cols="12" sm="6">
                          {{ data.verificationName }} </v-col
                        ><v-col cols="12" sm="6">
                          Score -
                          {{ data.score }}
                          Threshold -
                          {{ data.threshold }}
                          <template v-slot:actions>
                            <!--<v-icon color="green" v-if="data.score > 70">
                    mdi-check
                  </v-icon>
                  <v-icon color="orange" v-else-if="data.score > 50">
                    mdi-alert
                  </v-icon>
                  <v-icon color="red" v-else>
                    mdi-close
                  </v-icon>-->
                          </template>
                        </v-col>
                      </v-row></v-expansion-panel-header
                    >

                    <v-expansion-panel-content>
                      <span class="text-decoration-underline font-weight-bold">
                        Type
                      </span>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.verificationType }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <span class="text-decoration-underline font-weight-bold">
                        Category
                      </span>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.verificationCategory
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <span class="text-decoration-underline font-weight-bold">
                        Score
                      </span>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Score: {{ data.score }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Threshold:
                            {{ data.threshold }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >Result Value:
                            {{ data.resultValue }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-content>

                    <v-expansion-panel-content>
                      <span class="text-decoration-underline font-weight-bold">
                        Outputs
                      </span>
                      <v-list-item
                        two-line
                        v-for="(data, name) in data.outputs"
                        :key="name"
                      >
                        <v-list-item-content>
                          <span class="text-decoration-underline d">
                            <v-list-item-title>{{
                              data.name
                            }}</v-list-item-title>
                          </span>
                          <v-expansion-panel-content>{{
                            data.text
                          }}</v-expansion-panel-content>
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-content>

                    <v-spacer />
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </div>
            <div align="center" v-else>
              RF Verification Results and Scores will show here when available
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider class="mx-4 ma-6"></v-divider>
    <v-expansion-panels accordion focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-card-title v-if="data"
            >Verification Results
            <span v-if="data.metadata"
              >- {{ data.metadata.numberOfPositiveVerifications }} of
              {{ data.metadata.totalVerifications }}</span
            ></v-card-title
          >
          <v-card-title v-else>Verification Results</v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="flex xs12 md12 pa-6">
            <div v-if="data">
              <v-row justify="center">
                <v-expansion-panels accordion focusable>
                  <v-expansion-panel
                    v-for="(data, name) in data.result.actions"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      <v-row class="xs12 md12">
                        <v-col cols="12" sm="6">
                          {{ data.verificationName }} </v-col
                        ><v-col cols="12" sm="6">
                          Score -
                          {{ data.score }}
                          Threshold -
                          {{ data.threshold }}
                          <template v-slot:actions>
                            <!--<v-icon color="green" v-if="data.score > 70">
                    mdi-check
                  </v-icon>
                  <v-icon color="orange" v-else-if="data.score > 50">
                    mdi-alert
                  </v-icon>
                  <v-icon color="red" v-else>
                    mdi-close
                  </v-icon>-->
                          </template>
                        </v-col>
                      </v-row></v-expansion-panel-header
                    >

                    <v-expansion-panel-content>
                      <span class="text-decoration-underline font-weight-bold">
                        Type
                      </span>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.verificationType }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <span class="text-decoration-underline font-weight-bold">
                        Category
                      </span>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.verificationCategory
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <span class="text-decoration-underline font-weight-bold">
                        Score
                      </span>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Score: {{ data.score }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Threshold:
                            {{ data.threshold }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >Result Value:
                            {{ data.resultValue }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-content>

                    <v-expansion-panel-content>
                      <span class="text-decoration-underline font-weight-bold">
                        Outputs
                      </span>
                      <v-list-item
                        two-line
                        v-for="(data, name) in data.outputs"
                        :key="name"
                      >
                        <v-list-item-content>
                          <span class="text-decoration-underline d">
                            <v-list-item-title>{{
                              data.name
                            }}</v-list-item-title>
                          </span>
                          <v-expansion-panel-content>{{
                            data.text
                          }}</v-expansion-panel-content>
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-content>

                    <v-spacer />
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </div>
            <div align="center" v-else>
              Verification Results and Scores will show here when available
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <div class="flex xs12 md12">
            <div class="row row-equal">
              <div align="center"
                class="cards flex xs12 md6 lg3"
                v-for="(data, name) in idvData.actions"
                :key="name">


      <IDVResultItemCard
                    :title="data.verificationType"
                    :score="data.score"
                    :name="data.verificationName"
                    :content="data"
                  />  
    </div>
      </div>
    </div>
-->

    <v-card-actions>
      <v-dialog v-model="rawDataDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" text v-bind="attrs" v-on="on">
            DATA
          </v-btn>
        </template>
        <v-card>
          <v-expansion-panels v-if="data">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Actions
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in data.result.actions"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Results
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in data.result"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Metadata
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in data.metadata"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-else>
            Verification Results and Scores will show here when available
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="rawDataDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      
          <v-btn color="primary" text v-show="this.data" @click="downloadRawData(item)">
            DOWNLOAD
          </v-btn>
        

      <v-dialog v-if="rfVerifyOn" v-model="rawRFDataDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" text v-bind="attrs" v-on="on">
            RF DATA
          </v-btn>
        </template>
        <v-card>
          <v-expansion-panels v-if="rfVerificationData">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Actions
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in rfVerificationData.result.chipResult
                      .actions"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Results
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in rfVerificationData.result"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Metadata
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in rfVerificationData.metadata"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-else>
            Verification Results and Scores will show here when available
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="rawRFDataDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-if="faceMatchVerifyOn" v-model="rawFaceMatchDataDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" text v-bind="attrs" v-on="on">
            FACEMATCH DATA
          </v-btn>
        </template>
        <v-card>
          <v-expansion-panels v-if="faceMatchData">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Results
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in faceMatchData"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- <v-expansion-panel>
              <v-expansion-panel-header>
                Metadata
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(data, name) in data.metadata"
                    :key="name"
                  >
                    <v-expansion-panel-header>
                      {{ name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ data }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>-->
          </v-expansion-panels>
          <div v-else>
            Verification Results and Scores will show here when available
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="rawFaceMatchDataDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import download from 'downloadjs';

//  import testData from "../../../public/testData.json";
//import IDVResultItemCard from "@/components/cards/IDVResultItemCard.vue";
export default {
  name: "IDVResultCard",
  props: {
    title: String,
    data: Object,
    loading: Boolean,
    faceMatchLoading: Boolean,
    rfLoading: Boolean,
    reset: Boolean,
    faceMatchData: Object,
    rfVerificationData: Object
  },
  data: () => ({
    started: null,
    running: false,
    content: "",
    image: "",
    rawDataDialog: false,
    rawRFDataDialog: false,
    rawFaceMatchDataDialog: false,
    documentVerifyOn: false,
    rfVerifyOn: false,
    faceMatchVerifyOn: false
  }),
  mounted() {
    if (localStorage.documentVerifySwitch) {
      this.documentVerifyOn = JSON.parse(localStorage.documentVerifySwitch);
    }
    if (localStorage.rfVerifySwitch) {
      this.rfVerifyOn = JSON.parse(localStorage.rfVerifySwitch);
    }
    if (localStorage.faceMatchVerifySwitch) {
      this.faceMatchVerifyOn = JSON.parse(localStorage.faceMatchVerifySwitch);
    }
  },

  created() {},
  watch: {
    data: function() {
      if (this.data) {
        this.image = "data:image/jpg;base64," + this.data.result.portrait;
      } else {
        this.image = "../../../public/img/passport.jpg";
      }

      if (localStorage.documentVerifySwitch) {
        this.documentVerifyOn = JSON.parse(localStorage.documentVerifySwitch);
      }
      if (localStorage.rfVerifySwitch) {
        this.rfVerifyOn = JSON.parse(localStorage.rfVerifySwitch);
      }
      if (localStorage.faceMatchVerifySwitch) {
        this.faceMatchVerifyOn = JSON.parse(localStorage.faceMatchVerifySwitch);
      }
    },
    loading: function() {
      if (localStorage.documentVerifySwitch) {
        this.documentVerifyOn = JSON.parse(localStorage.documentVerifySwitch);
      }
      if (localStorage.rfVerifySwitch) {
        this.rfVerifyOn = JSON.parse(localStorage.rfVerifySwitch);
      }
      if (localStorage.faceMatchVerifySwitch) {
        this.faceMatchVerifyOn = JSON.parse(localStorage.faceMatchVerifySwitch);
      }
    },
    faceMatchData: function() {},
    reset: function() {
      this.resetData();
    }
  },
  computed: {
    // a computed getter
    idvResults: function() {
      return this.data;
      // console.log(data_buffer.toString('hex'));
      // console.log(data_buffer.toString('utf8'))
    },
    idvFaceMatchResults: function() {
      return this.faceMatchData;
    },

    bytedata: function() {
      var data_buffer = Buffer.from(this.content, "base64");
      return data_buffer.toString("hex");
    }
  },
  methods: {
    resolveimage: function() {
      if (this.data) {
        return "data:image/jpg;base64," + this.data.result.portrait;
      } else {
        return "../../../public/img/passport.jpg";
      }
    },
    resetData: function() {
      this.data = {};
    },
    downloadRawData: function() {

      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      download(JSON.stringify(this.data), `${day}-${month}-${year}`+"@"+ date.getTime()+"-result"+".json", "text/plain");
    }
  }
};
</script>

<style scoped>
.cards {
  padding: 10px;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.v-sheet--offset {
  top: 12px;
  position: relative;
}

.timer {
  display: inline;
}
</style>
